import { permissions } from '@mpk/shared/domain';

enum IdentityUsersPermissionsEnum {
  None = 0,
  /**
   * Manage users of the own organization.
   */
  ManageOrganizationUsers = 1,

  /**
   * Manage users and roles of all organizations.
   */
  ManageAllUserAndRoles = 2 | ManageOrganizationUsers,
}

export const IdentityUsersPermissions = permissions(IdentityUsersPermissionsEnum, {
  featureId: 'IdentityUsers',
});
