import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';

import { HeaderElementDirective } from '@core/shared/util';
import { RootModuleNavComponent } from '@core/ui';
import { UsersFacade } from '@mp/kernel/users/data-access';
import { IdentityUserListItem } from '@mp/kernel/users/domain';
import { UsersListViewComponent } from '@mp/kernel/users/ui';

@Component({
  selector: 'mpk-users-list',
  standalone: true,
  templateUrl: './users-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UsersListViewComponent, HeaderElementDirective, RootModuleNavComponent],
})
export class UsersListComponent {
  protected readonly users: Signal<IdentityUserListItem[]> = this.usersFacade.users;

  protected readonly usersLoaded: Signal<boolean> = this.usersFacade.usersLoaded;

  constructor(private readonly usersFacade: UsersFacade) {
    this.loadUsers();
  }

  loadUsers(searchTerm?: string): void {
    this.usersFacade.fetchUsers(searchTerm);
  }
}
